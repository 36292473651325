import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { useEffect, useRef } from 'react';
import { ConnectDataWithSettingsDto } from '../model/ConnectDataDto';
import './JitsiIntegration.scss';

interface Props {
  joinData: ConnectDataWithSettingsDto;
  onJitsiClose: () => void;
}
const JitsiIntegration = (props: Props) => {
  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handleMessageFromConference = (event: any) => {
      const { origin, data } = event;
      if (origin !== props.joinData.clientUrl) {
        return;
      }

      switch (data.type) {
        case 'STATUS_CHANGE':
          if (data.data === 'CONFERENCE_OUT' || data.data === 'LEAVING_CONFERENCE') {
            props.onJitsiClose();
          }
          break;
      }
    };

    window.addEventListener('message', handleMessageFromConference);
    return () =>
      window.removeEventListener('message', handleMessageFromConference);
  }, []);

  const onIFrameLoad = () => {
    console.log("INTEGRATION JWT: ", props.joinData.jwt);
    setTimeout(() => {
      ref.current?.contentWindow?.postMessage(
        {
          type: 'JITSI_CONFIG',
          data: {
            // domain: props.joinData.jitsiUrl, <-- moved to jwt
            // domain: 'meet.jit.si',
            // roomToken: props.joinData.roomToken, <-- was already in jwt //"LaZWUnZhrBT9yk7IV7IasfsFJcVL2i0NpbRZx42haC92DLh4uv0D9ZSnUObsMqyvZOQOKvuRLa3w0paLAhsqtSTddFXGf_lgQwZdPndWJcmwUCMbBrKfRL-JaQSIQ",
            jwt: props.joinData.jwt, //"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJDbzdoczdrd0M5eDQ0MjNrTnJ6WUNWeVloNjY0b3hIaSIsInN1YiI6ImppdHNpLWVsdmkyLmVsdmkud29ybGQiLCJtb2RlcmF0b3Ii`On`RydWUsImlzcyI6IkNvN2hzN2t3Qzl4NDQyM2tOcnpZQ1Z5WWg2NjRveEhpIiwiY29udGV4dCI6eyJ1c2VyIjp7Im5hbWUiOiJIb3NzIiwiaWQiOiJmMzFhMGNjOS0xOTQ4LTQyYzEtOTZhOC1hMzg4MjM3YjU0M2EifSwiZ3JvdXAiOiJpbnRlcm4uZWx2aS5kZSJ9LCJleHAiOjkwNjYwMjQwODAwLCJyb29tIjoiTGFaV1VuWmhyQlQ5eWs3SVY3SWFzZnNGSmNWTDJpME5wYlJaeDQyaGFDOTJETGg0dXYwRDlaU25VT2JzTXF5dlpPUU9LdnVSTGEzdzBwYUxBaHNxdFNUZGRGWEdmX2xnUXdaZFBuZFdKY213VUNNYkJyS2ZSTC1KYVFTSVEiLCJwbHVnaW5zIjpbeyJtYW5pZmVzdCI6Imh0dHA6Ly9sb2NhbGhvc3Q6MzAwMC9wbHVnaW5NYW5pZmVzdC5qc29uIn0seyJtYW5pZmVzdCI6Imh0dHA6Ly9sb2NhbGhvc3Q6MzAwMC9wbHVnaW5NYW5pZmVzdDIuanNvbiJ9XX0.xFO1DvGj6wh_L04bl_xOyfIivasfyZes1ypN-whKSiw",
            lang: props.joinData.lang,
            // socketDomain: props.joinData.socketDomain, <-- moved to jwt //"conference-client-intern.elvi.world",
            // socketProtocol: props.joinData.socketProtocol, <-- moved to jwt // "http",
            settings: {
              forceE2EE: props.joinData.forceE2EE,
              useManagedE2EEMode: props.joinData.managedKeyMode,
              startVideoMuted: false,
              startAudioMuted: false,
            },
            configOverwrite: {
              disableSimulcast: false,
              toolbarButtons: [...toobarButtons],
              ...(props.joinData.configOverwrites || {}),
            },
          },
        },
        props.joinData.clientUrl
      );
    }, 100);
    // added some timeout because it seemed that on safari the listener was not initialized when the message was sent
  };

  const handleClose = () => {
    ref.current?.contentWindow?.postMessage(
      {
        type: 'JITSI_API_COMMAND',
        data: {
          command: 'HANG_UP_CALL',
        },
      },
      props.joinData.clientUrl
    );
  };

  return (
    <Box>
      <AppBar>
        <Toolbar sx={{minHeight: "64px"}}>
          <Stack
            flexDirection={'row'}
            alignItems='center'
            justifyContent={'space-between'}
            width='100%'>
            <Typography variant='h6'>
              CGM-CONFERENCE-CLIENT DEMO INTEGRATION
            </Typography>

            <Tooltip title='Hang Up Call'>
              <IconButton
                aria-label='Hang Up Call'
                onClick={handleClose}
                sx={{ color: 'white' }}>
                <CallEndIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
      </AppBar>
      <Stack className='jitsi-container' flexDirection={'column'}>
        <Box sx={{ height: '100%', paddingTop: "64px" }}>
          <iframe
            onLoad={onIFrameLoad}
            ref={ref}
            src={`${props.joinData.clientUrl}?origin=${window.location.origin}`}
            title={'jitsi'}
            allow='camera; microphone; display-capture; autoplay; clipboard-read; clipboard-write;'
          />
        </Box>
      </Stack>
    </Box>
  );
};
export default JitsiIntegration;

const toobarButtons: Array<string> = [
  'microphone',
  'camera',
  'closedcaptions',
  'desktop',
  'fodeviceselection',
  'hangup',
  'raisehand',
  'videoquality',
  'filmstrip',
  'tileview',
  'settings',
  'select-background',
  'stats',
];
