import React, { useEffect, useState } from "react";
import "./App.scss";
import JitsiIntegration from "./components/JitsiIntegration";
import JoinForm from "./components/JoinForm";
import { ConnectDataDto, ConnectDataWithSettingsDto } from "./model/ConnectDataDto";
import { sendJWTCreate } from "./utils";

export const SESSIONSTORAGE_INITIAL_FORM = "s_form";
const defaultInitialData = {
  isModerator: true,
  managedKeyMode: false,
  forceE2EE: true,
  advancedOptions:  true,
  configOverwrites: {}
};

const setIntiialData = () => {
  try {
    const data = localStorage.getItem(SESSIONSTORAGE_INITIAL_FORM);
    if (data) {
      return JSON.parse(data);
    } else {
      return defaultInitialData;
    }
  } catch (err) {
    return defaultInitialData;
  }
};

const loadEnvironments = async () => {
  const response = await fetch("/api/jwt/environments");
  return response.json();
};

const loadPlugins = async () => {
  const response = await fetch("/api/jwt/plugins");
  return response.json();
};

const languageList = [
  {key: "en", label: "English"},
  {key: "de", label: "German"}
]

function App() {

  const [formInitialValues, setFormInitialValues] = useState<any>(
    setIntiialData()
  );
  const [jitsiJoinData, setJitsiJoinData] = useState<ConnectDataWithSettingsDto | null>(
    null
  );
  
  const [environmentList, setEnvironmentList] = useState<null | any[]>(null);
  const [pluginList, setPluginList] = useState<null | any[]>(null);

  useEffect(() => {
    loadPlugins().then((plugins) => setPluginList(plugins)).catch(err => console.error("error at loading plugins", err));
    loadEnvironments()
      .then((environments) => setEnvironmentList(environments))
      .catch((err) => {
        console.error("error at loading environments", err);
      });

    const queryString = window.location.search;
    if (queryString.includes("userId") && queryString.includes("displayName") && queryString.includes("roomToken")) {
      const urlParams = new URLSearchParams(queryString);
      const sessionStorage = localStorage.getItem(SESSIONSTORAGE_INITIAL_FORM);

      if (sessionStorage) {
        const sessionStorageJSON = JSON.parse(sessionStorage);
        
        sendJWTCreate({
          ...sessionStorageJSON,
          userId: urlParams.get('userId'),
          displayName: urlParams.get('displayName'),
          roomToken: urlParams.get('roomToken'),
        }).then(data => {
          setJitsiJoinData({
            ...data, 
            lang: 'en', 
            managedKeyMode: false, 
            forceE2EE: true, 
            configOverwrites: {}
          });
        })
      }
    }   
  }, []);




  return (
    <>
      {(!environmentList || !pluginList) && <div>Loading environments...</div>}
      {environmentList && pluginList && (
        <>
          {jitsiJoinData && (
            <JitsiIntegration
              onJitsiClose={() => {
                setJitsiJoinData(null);
              }}
              joinData={jitsiJoinData}
            />
          )}
          {!jitsiJoinData && (
            <JoinForm
              pluginList={pluginList}
              environmentList={environmentList}
              languageList={languageList}
              initialData={formInitialValues}
              onReceiveJitsiJoinData={(data, formResult) => {
                setJitsiJoinData({...data, lang: formResult.lang, managedKeyMode: formResult.managedKeyMode, forceE2EE: formResult.forceE2EE, configOverwrites: formResult.advancedOptions ? formResult.configOverwrites: {}});
                setFormInitialValues(formResult);
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export default App;
