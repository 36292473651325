import { ConnectDataDto } from "./model/ConnectDataDto";

export async function sendJWTCreate(values: any) {

    console.log("submit", values);
    const { forceE2EE, managedKeyMode, advancedOptions, configOverwrites, lang, ...restValues } = values;
    const response = await fetch("/api/jwt/create", {
      method: "POST",
      body: JSON.stringify(restValues),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data: ConnectDataDto = await response.json();
    console.log("response", data);

    return data;

}